import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import ProfileItem from '../components/ProfileItem'
import SEO from '../components/seo'

import { Grid } from '@material-ui/core'
import PageTitle from '../components/PageTitle'


const Team = () => {
  const query = useStaticQuery(graphql`
{
  allFile(filter: {childMarkdownRemark: {frontmatter: {id: {eq: "team"}}}}) {
    nodes {
      childMarkdownRemark {
        frontmatter {
          id
          title
          seoDescription
          seoKeywords
          seoTitle
          people {
            img {
              childImageSharp {
                fluid {
                  base64
                  tracedSVG
                  srcWebp
                  srcSetWebp
                  originalImg
                  originalName
                }
              }
            }, 
            name,
            subtitle,
            id
          }
        }
      }
    }
  }
}
`)

  const profiles = query.allFile.nodes[0].childMarkdownRemark.frontmatter.people
  const pageTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.title
  const seoDescription = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoDescription
  const seoKeywords = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoKeywords
  const seoTitle = query.allFile.nodes[0].childMarkdownRemark.frontmatter.seoTitle

  return (
    <Layout>
      <SEO title={seoTitle.length !== 0 ? seoTitle : pageTitle} description={seoDescription.length !== 0 ? seoDescription : null} keywords={seoKeywords.length !== 0 ? seoKeywords : null}/>
      <PageTitle title={pageTitle} />
      <Grid container spacing={8}>
        {profiles.map((profile) => (
          <ProfileItem profile={profile} key={profile.name} />
        ))}
      </Grid>
    </Layout>
  )
}
export default Team
